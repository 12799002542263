import React, { useState, useEffect } from 'react'
import ZonePopUpParent from './ZonePopUpParent'
import './styles/zone.scss'
import { statusToLogo } from './utility/statusToLogo'
import ZonePopUpSingle from './ZonePopUpSingle'
import { ReactComponent as Gallery } from './icons/gallery.svg'
import determineOverallStatus from './utility/determineOverallStatus'

export default function Zone({ zone }) {
  const [isModal, setModal] = useState(false)

  let classNameStatus = determineOverallStatus(zone)
  let logoStatus = statusToLogo(classNameStatus)

  return (
    <>
      <div className="zone" onClick={() => setModal(true)}>
          <div className="singleZone-status-container">
            <div className={`zone-status zone-status-${classNameStatus}`}>
              {classNameStatus === 'err' && 'Ошибка'}
              {classNameStatus === 'on' && 'Включено'}
              {classNameStatus === 'off' && 'Выключено'}
              <div className="zone-status-logo">{logoStatus}</div>
            </div>
            {zone.innerZones && (
              <div className="gallery">
                <Gallery />
              </div>
            )}
          </div>
                <div className="zone-bg">
          <img
            src={`img/${zone.id}.png`}
            alt=""
            onError={e => {
              e.target.src = 'img/nameZone1.png'
            }}
          />
        </div>
        <div className="zone-title">{zone.name}</div>
      </div>
      {zone.status && (
        <ZonePopUpSingle
          isVisible={isModal}
          zone={zone}
          onClose={() => setModal(false)}
        />
      )}
      {zone.innerZones && (
        <ZonePopUpParent
          isVisible={isModal}
          zone={zone}
          onClose={() => setModal(false)}
        />
      )}
    </>
  )
}
