import { statusToClassName } from './utility/statusToClassName'
import React from 'react'
import { ReactComponent as StatusCirlce } from './icons/status_circle.svg'
import iconMapping from './utility/iconMapping'

export default function ZoneStatusValuesComponent({ statusObject }) {
  return (
    <div className="status-container">
      {Object.entries(statusObject).map(([key, value]) => {
        const classNameStatus = statusToClassName(value)
        const StatusIconComponent = iconMapping[key]
        return (
          <div className="status-info" key={key}>
            <div className="status-name">
              {StatusIconComponent && <StatusIconComponent />}
            </div>
            <div className={`status-value status-value-${classNameStatus}`}>
              <StatusCirlce />
            </div>
          </div>
        )
      })}
    </div>
  )
}
