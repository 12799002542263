import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Slider from '@mui/material/Slider'

export default function BrightSlider({ id, button }) {
  const [brightValue, setBrightValue] = useState(
    JSON.parse(localStorage.getItem(`${id}-bright`) || 0),
  )
  const brightHandler = async (event, newBrightValue) => {
    setBrightValue(newBrightValue)
    window.localStorage.setItem(`${id}-bright`, JSON.stringify(newBrightValue))
    try {
      await axios.post(button.url, {
        zone: id,
        command: newBrightValue.toString(),
        id: button.id,
      })
      console.log(
        'zone bright ->',
        id,
        'newbrightValue-->',
        newBrightValue.toString(),
        'id button ->',
        button.id,
      )
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <div className="slider-place">
      <Slider
        aria-label="Bright"
        value={brightValue}
        onChange={brightHandler}
      />
    </div>
  )
}
