import { ReactComponent as Error } from '../icons/attention-white.svg'
import { ReactComponent as PowerWhite } from '../icons/power-white.svg'

export const statusToLogo = status => {
  if (status === 'on' || status === 'off') {
    return <PowerWhite />
  } else {
    return <Error />
  }
}
