// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body.shedule {
  width: 360px;
}

.schedule-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.schedule-container .schedule-container-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.schedule-container__title {
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  text-align: left;
  color: var(--text);
  width: 100%;
}

.schedule-container__time-picker-wrapper {
  border-radius: 20px;
  width: 100%;
  background: #f3f3f3;
}`, "",{"version":3,"sources":["webpack://./src/styles/schedulePopUp.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;AAEJ;;AAEA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;AACF;;AAEA;EACE,mBAAA;EACA,WAAA;EACA,mBAAA;AACF","sourcesContent":[".modal-body.shedule {\n  width: 360px;\n}\n\n.schedule-container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n  .schedule-container-item {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    align-items: center;\n  }\n}\n\n.schedule-container__title {\n  font-weight: 400;\n  font-size: 20px;\n  line-height: 150%;\n  text-align: left;\n  color: var(--text);\n  width: 100%;\n}\n\n.schedule-container__time-picker-wrapper {\n  border-radius: 20px;\n  width: 100%;\n  background: #f3f3f3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
