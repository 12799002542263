import React, { useEffect, useState } from 'react'
import './styles/header.scss'
import { ReactComponent as MainLogo } from './icons/logo.svg'
import { ReactComponent as InfoLogo } from './icons/info.svg'
import { ReactComponent as RestartLogo } from './icons/restart-mid.svg'
import StatusPopUp from './StatusPopUp'
import AlertPopUp from './AlertPopUp'

export default function Header({
  underFloor,
  firstFloor,
  secondFloor,
  thirdFloor,
}) {
  const [isStatusModal, setStatusModal] = useState(false)
  const [isAlertModal, setAlertModal] = useState(false)

  return (
    <div className="header">
      <div className="main-logo">
        <MainLogo />
      </div>
      <div className="header-buttons">
        <div
          className="header-buttons__button"
          onClick={() => setStatusModal(true)}
        >
          <div className="logo status">
            <InfoLogo />
          </div>
          <span>Статусы залов</span>
        </div>

        <StatusPopUp
          isVisible={isStatusModal}
          underFloor={underFloor}
          firstFloor={firstFloor}
          secondFloor={secondFloor}
          thirdFloor={thirdFloor}
          onClose={() => setStatusModal(false)}
        />
        <div
          className="header-buttons__button"
          onClick={() => setAlertModal(true)}
        >
          <div className="logo restart">
            <RestartLogo />
          </div>
          <span>Перезапустить</span>
        </div>

        <AlertPopUp
          isVisible={isAlertModal}
          onClose={() => setAlertModal(false)}
        />
      </div>
    </div>
  )
}
