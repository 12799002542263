import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { ReactComponent as Restart } from './icons/restart-small.svg'

export default function RestartButton({ id, button }) {
  const restartHandler = () => {
    try {
      console.log(button.url, ' -> ', id, 'id button ->', button.id)
      axios.post(button.url, { zone: id, id: button.id })
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <div className="zone-buttons-container">
      <button
        onClick={restartHandler}
        type="button"
        className="button_yellow"
        title="Перазагрузить"
      >
        <Restart />
      </button>
    </div>
  )
}
