import React, { useEffect, useState } from 'react'
import { ReactComponent as Close } from './icons/close.svg'
import SingleZoneStatus from './SingleZoneStatus'
import './styles/zonePopUpParent.scss'
import ZonePopUpSingle from './ZonePopUpSingle'
import determineOverallStatus from './utility/determineOverallStatus'
import { statusToLogo } from './utility/statusToLogo'

function ZonePopUpParent({ isVisible = false, zone, onClose }) {
  const [visibleModals, setVisibleModals] = useState({})

  const keydownHandler = ({ key }) => {
    switch (key) {
      case 'Escape':
        onClose()
        break
      default:
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', keydownHandler)
    return () => document.removeEventListener('keydown', keydownHandler)
  })

  const handleModalToggle = (zoneName, state) => {
    setVisibleModals(prevState => ({
      ...prevState,
      [zoneName]: state,
    }))
  }

  let classNameStatus = determineOverallStatus(zone)
  let logoStatus = statusToLogo(classNameStatus)

  return !isVisible ? null : (
    <div className="modal-background" onClick={onClose}>
      <div
        className={`modal-body parent-zone-modal ${isVisible ? 'open' : 'closed'}`}
        onClick={e => e.stopPropagation()}
      >
        <div className="modal-header">
          {zone.name}
          <div className="close-logo" onClick={onClose}>
            <Close />
          </div>
        </div>
        <div className="zoneWithInnerZones popupParent">
          <div className="innerZonesArray">
            {zone.innerZones.map(innerZone => (
              <React.Fragment key={innerZone.name}>
                <div onClick={() => handleModalToggle(innerZone.name, true)}>
                  <SingleZoneStatus
                    key={innerZone.name}
                    name={innerZone.name}
                    status={innerZone.status}
                    isChild={true}
                    withText={true}
                    classNameStatus={classNameStatus}
                    logoStatus={logoStatus}
                  />
                </div>
                <ZonePopUpSingle
                  isVisible={!!visibleModals[innerZone.name]}
                  zone={innerZone}
                  onClose={() => handleModalToggle(innerZone.name, false)}
                />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(ZonePopUpParent)
