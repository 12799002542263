// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zoneWithInnerZones {
  width: 100%;
}

.parent-zone-modal .singleZone.child {
  cursor: pointer;
  border-radius: 20px;
  padding: 0px 20px;
  width: 100%;
  height: 50px;
  background: #f3f3f3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.parent-zone-modal .modal-background {
  z-index: 1000;
  background: rgba(67, 67, 67, 0.17);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.innerZonesArray {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.innerZonesArray .status-container {
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/styles/zonePopUpParent.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAIE;EACE,eAAA;EACA,mBAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AADJ;AAGE;EACE,aAAA;EACA,kCAAA;EACA,kCAAA;UAAA,0BAAA;AADJ;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAFF;AAGE;EACE,yBAAA;AADJ","sourcesContent":[".zoneWithInnerZones {\n  width: 100%;\n}\n\n.parent-zone-modal {\n  // width: 380px !important;\n  .singleZone.child {\n    cursor: pointer;\n    border-radius: 20px;\n    padding: 0px 20px;\n    width: 100%;\n    height: 50px;\n    background: #f3f3f3;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n  }\n  .modal-background {\n    z-index: 1000;\n    background: rgb(67 67 67 / 17%);\n    backdrop-filter: blur(5px);\n  }\n}\n\n.innerZonesArray {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  .status-container {\n    justify-content: flex-end;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
