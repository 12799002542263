import axios from 'axios'
import React, { useEffect, useState } from 'react'
import BasicTimePicker from './TimePicker'
import './styles/schedulePopUp.scss'
import { ReactComponent as Close } from './icons/close.svg'
import dayjs from 'dayjs'

export default function Schedule({
  isVisible,
  onClose,
  timeStart,
  savedTimeStart,
  setTimeStart,
  timeEnd,
  savedTimeEnd,
  setTimeEnd,
}) {
  //вкл выкл
  const powerHandler = (value = 'on') => {
    try {
      axios.post('/power/park', {
        command: value,
      })
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (!savedTimeStart) {
      localStorage.setItem(`timeStart`, timeStart.toISOString())
    }
    if (!savedTimeEnd) {
      localStorage.setItem(`timeEnd`, timeEnd.toISOString())
    }
  }, [])

  // если время наступило, не больше одного раза в минуту вызываем функцию
  const [lastCheckedTimeStart, setLastCheckedTimeStart] = useState(null)
  const [lastCheckedTimeEnd, setLastCheckedTimeEnd] = useState(null)

  useEffect(() => {
    const checkTimeAndExecute = () => {
      const currentTime = dayjs()
      const isTimeStart =
        timeStart.hour() === currentTime.hour() &&
        timeStart.minute() === currentTime.minute()
      if (isTimeStart && lastCheckedTimeStart !== currentTime.minute()) {
        powerHandler('on')
        setLastCheckedTimeStart(currentTime.minute())
      }
      const isTimeEnd =
        timeEnd.hour() === currentTime.hour() &&
        timeEnd.minute() === currentTime.minute()
      if (isTimeEnd && lastCheckedTimeEnd !== currentTime.minute()) {
        powerHandler('off')
        setLastCheckedTimeEnd(currentTime.minute())
      }
    }
    // Запуск проверки каждую минуту
    const intervalId = setInterval(checkTimeAndExecute, 60000)
    // Очистка интервала при размонтировании компонента
    return () => clearInterval(intervalId)
  }, [timeStart, timeEnd, lastCheckedTimeStart, lastCheckedTimeEnd])

  //когда устанавливают расписание отправляем его на бэк
  useEffect(() => {
    try {
      axios.post('/timeOn', {
        command: `${timeStart.hour().toString().padStart(2, '0')}${timeStart.minute().toString().padStart(2, '0')}`,
      })
    } catch (err) {
      console.error(err)
    }
  }, [timeStart])

  useEffect(() => {
    try {
      axios.post('/timeOff', {
        command: `${timeEnd.hour().toString().padStart(2, '0')}${timeEnd.minute().toString().padStart(2, '0')}`,
      })
    } catch (err) {
      console.error(err)
    }
  }, [timeEnd])

  return !isVisible ? null : (
    <div className="modal-background" onClick={onClose}>
      <div
        className={`modal-body shedule ${isVisible ? 'open' : 'closed'}`}
        onClick={e => e.stopPropagation()}
      >
        <div className="modal-header">
          Настройка расписания
          <div className="close-logo" onClick={onClose}>
            <Close />
          </div>
        </div>
        <div className="schedule-container">
          <div className="schedule-container-item">
            <div className="schedule-container__title">Включение:</div>
            <div className="schedule-container__time-picker-wrapper">
              <BasicTimePicker
                time={timeStart}
                setTime={setTimeStart}
                storageKey="timeStart"
              />
            </div>
          </div>

          <div className="schedule-container-item">
            <div className="schedule-container__title">Выключение:</div>
            <div className="schedule-container__time-picker-wrapper">
              <BasicTimePicker
                time={timeEnd}
                setTime={setTimeEnd}
                storageKey="timeEnd"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
