import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { ReactComponent as Power } from './icons/power-white.svg'
import { ReactComponent as Off } from './icons/off.svg'

export default function PowerButtons({ id, button }) {
  const powerHandler = e => {
    try {
      const command = e.currentTarget.getAttribute('data-value')
      console.log(
        'url -> ',
        button.url,
        'id zone -> ',
        id,
        'command ->',
        command,
        'id button ->',
        button.id,
      )
      if (button.url.startsWith('/power')) {
        axios.post(button.url, { zone: id, command, id: button.id })
      } else {
        axios.post('/light/lamp', { zone: id, id: button.id })
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className="zone-buttons-container">
      <button
        onClick={powerHandler}
        type="button"
        className="button_green"
        data-value="on"
        title="Включить"
      >
        <Power />
      </button>
      {button.url.startsWith('/power') && (
        <button
          onClick={powerHandler}
          type="button"
          className="button_red"
          data-value="off"
          title="Выключить"
        >
          <Off />
        </button>
      )}
    </div>
  )
}
