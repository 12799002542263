import { ReactComponent as Camera1Icon } from '../icons/camera_1.svg'
import { ReactComponent as Camera2Icon } from '../icons/camera_2.svg'
import { ReactComponent as Camera3Icon } from '../icons/camera_3.svg'
import { ReactComponent as Camera4Icon } from '../icons/camera_4.svg'
import { ReactComponent as Camera5Icon } from '../icons/camera_5.svg'
import { ReactComponent as Controller1Icon } from '../icons/controller_1.svg'
import { ReactComponent as Controller2Icon } from '../icons/controller_2.svg'
import { ReactComponent as Controller3Icon } from '../icons/controller_3.svg'
import { ReactComponent as Controller4Icon } from '../icons/controller_4.svg'
import { ReactComponent as Controller5Icon } from '../icons/controller_5.svg'
import { ReactComponent as Controller6Icon } from '../icons/controller_6.svg'
import { ReactComponent as Controller7Icon } from '../icons/controller_7.svg'
import { ReactComponent as Controller8Icon } from '../icons/controller_8.svg'
import { ReactComponent as Controller9Icon } from '../icons/controller_9.svg'
import { ReactComponent as Controller10Icon } from '../icons/controller_10.svg'
import { ReactComponent as Display1Icon } from '../icons/display_1.svg'
import { ReactComponent as Display2Icon } from '../icons/display_2.svg'
import { ReactComponent as Display3Icon } from '../icons/display_3.svg'
import { ReactComponent as Display4Icon } from '../icons/display_4.svg'
import { ReactComponent as Display5Icon } from '../icons/display_5.svg'
import { ReactComponent as Lidar1Icon } from '../icons/lidar_1.svg'
import { ReactComponent as Lidar2Icon } from '../icons/lidar_2.svg'
import { ReactComponent as Lidar3Icon } from '../icons/lidar_3.svg'
import { ReactComponent as Lidar4Icon } from '../icons/lidar_4.svg'
import { ReactComponent as Lidar5Icon } from '../icons/lidar_5.svg'
import { ReactComponent as Pc1Icon } from '../icons/pc_1.svg'
import { ReactComponent as Pc2Icon } from '../icons/pc_2.svg'
import { ReactComponent as Pc3Icon } from '../icons/pc_3.svg'
import { ReactComponent as Pc4Icon } from '../icons/pc_4.svg'
import { ReactComponent as Pc5Icon } from '../icons/pc_5.svg'
import { ReactComponent as Phone1Icon } from '../icons/phone_1.svg'
import { ReactComponent as Phone2Icon } from '../icons/phone_2.svg'
import { ReactComponent as Phone3Icon } from '../icons/phone_3.svg'
import { ReactComponent as Phone4Icon } from '../icons/phone_4.svg'
import { ReactComponent as Phone5Icon } from '../icons/phone_5.svg'
import { ReactComponent as Pj1Icon } from '../icons/pj_1.svg'
import { ReactComponent as Pj2Icon } from '../icons/pj_2.svg'
import { ReactComponent as Pj3Icon } from '../icons/pj_3.svg'
import { ReactComponent as Pj4Icon } from '../icons/pj_4.svg'
import { ReactComponent as Pj5Icon } from '../icons/pj_5.svg'
import { ReactComponent as Player1Icon } from '../icons/player_1.svg'
import { ReactComponent as Player2Icon } from '../icons/player_2.svg'
import { ReactComponent as Player3Icon } from '../icons/player_3.svg'
import { ReactComponent as Player4Icon } from '../icons/player_4.svg'
import { ReactComponent as Player5Icon } from '../icons/player_5.svg'
import { ReactComponent as Rfid1Icon } from '../icons/rfid_1.svg'
import { ReactComponent as Rfid2Icon } from '../icons/rfid_2.svg'
import { ReactComponent as Rfid3Icon } from '../icons/rfid_3.svg'
import { ReactComponent as Rfid4Icon } from '../icons/rfid_4.svg'
import { ReactComponent as Rfid5Icon } from '../icons/rfid_5.svg'
import { ReactComponent as Rfid6Icon } from '../icons/rfid_6.svg'
import { ReactComponent as Rfid7Icon } from '../icons/rfid_7.svg'
import { ReactComponent as Rfid8Icon } from '../icons/rfid_8.svg'
import { ReactComponent as Rfid9Icon } from '../icons/rfid_9.svg'
import { ReactComponent as Rfid10Icon } from '../icons/rfid_10.svg'
import { ReactComponent as Power} from '../icons/power-grey.svg'
import { ReactComponent as Lamp } from '../icons/lightbulb.svg'
import { ReactComponent as Volume } from '../icons/volume.svg'

const iconMapping = {
  camera_1: Camera1Icon,
  camera_2: Camera2Icon,
  camera_3: Camera3Icon,
  camera_4: Camera4Icon,
  camera_5: Camera5Icon,
  controller_1: Controller1Icon,
  controller_2: Controller2Icon,
  controller_3: Controller3Icon,
  controller_4: Controller4Icon,
  controller_5: Controller5Icon,
  controller_6: Controller6Icon,
  controller_7: Controller7Icon,
  controller_8: Controller8Icon,
  controller_9: Controller9Icon,
  controller_10: Controller10Icon,
  display_1: Display1Icon,
  display_2: Display2Icon,
  display_3: Display3Icon,
  display_4: Display4Icon,
  display_5: Display5Icon,
  lidar_1: Lidar1Icon,
  lidar_2: Lidar2Icon,
  lidar_3: Lidar3Icon,
  lidar_4: Lidar4Icon,
  lidar_5: Lidar5Icon,
  pc_1: Pc1Icon,
  pc_2: Pc2Icon,
  pc_3: Pc3Icon,
  pc_4: Pc4Icon,
  pc_5: Pc5Icon,
  phone_1: Phone1Icon,
  phone_2: Phone2Icon,
  phone_3: Phone3Icon,
  phone_4: Phone4Icon,
  phone_5: Phone5Icon,
  pj_1: Pj1Icon,
  pj_2: Pj2Icon,
  pj_3: Pj3Icon,
  pj_4: Pj4Icon,
  pj_5: Pj5Icon,
  player_1: Player1Icon,
  player_2: Player2Icon,
  player_3: Player3Icon,
  player_4: Player4Icon,
  player_5: Player5Icon,
  rfid_1: Rfid1Icon,
  rfid_2: Rfid2Icon,
  rfid_3: Rfid3Icon,
  rfid_4: Rfid4Icon,
  rfid_5: Rfid5Icon,
  rfid_6: Rfid6Icon,
  rfid_7: Rfid7Icon,
  rfid_8: Rfid8Icon,
  rfid_9: Rfid9Icon,
  rfid_10: Rfid10Icon,
  power: Power,
  lamp: Lamp,
  volume: Volume,
}

export default iconMapping
