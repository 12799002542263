import * as React from 'react'
import { createSvgIcon } from '@mui/material/utils'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import './styles/timepicker.scss'

const ClockIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0.69 0 20 20">
    <path
      d="M19.4355 9.98047C19.4355 6.85547 17.7559 4.00391 15.0605 2.44141C12.3262 0.839844 9.00586 0.839844 6.31055 2.44141C3.57617 4.00391 1.93555 6.85547 1.93555 9.98047C1.93555 13.1445 3.57617 15.9961 6.31055 17.5586C9.00586 19.1602 12.3262 19.1602 15.0605 17.5586C17.7559 15.9961 19.4355 13.1445 19.4355 9.98047ZM0.685547 9.98047C0.685547 6.42578 2.56055 3.14453 5.68555 1.34766C8.77148 -0.449219 12.5605 -0.449219 15.6855 1.34766C18.7715 3.14453 20.6855 6.42578 20.6855 9.98047C20.6855 13.5742 18.7715 16.8555 15.6855 18.6523C12.5605 20.4492 8.77148 20.4492 5.68555 18.6523C2.56055 16.8555 0.685547 13.5742 0.685547 9.98047ZM10.0605 4.35547C10.0605 4.04297 10.334 3.73047 10.6855 3.73047C10.998 3.73047 11.3105 4.04297 11.3105 4.35547V9.66797L14.748 11.9727C15.0605 12.168 15.1387 12.5586 14.9434 12.832C14.748 13.1445 14.3574 13.2227 14.084 13.0273L10.334 10.5273C10.1387 10.4102 10.0605 10.2148 10.0605 9.98047V4.35547Z"
      fill="#53565A"
    ></path>
  </svg>,
  'ClockIcon',
)

export default function BasicTimePicker({ time, setTime, storageKey }) {
  const handleChange = newValue => {
    setTime(newValue)
    localStorage.setItem(storageKey, newValue.toISOString())
  }
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopTimePicker
          value={time}
          onChange={handleChange}
          format="HH:mm"
          ampm={false}
          slots={{
            openPickerIcon: ClockIcon,
          }}
        />
      </LocalizationProvider>
    </>
  )
}
