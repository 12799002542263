import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { ReactComponent as Close } from './icons/close.svg'
import { ReactComponent as Cancel } from './icons/close-small.svg'
import { ReactComponent as Attention } from './icons/attention-red-transparent.svg'
import { ReactComponent as Restart } from './icons/restart-mid.svg'
import './styles/alertPopUp.scss'

export default function AlertPopUp({ isVisible = false, onClose }) {
  const keydownHandler = ({ key }) => {
    switch (key) {
      case 'Escape':
        onClose()
        break
      default:
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', keydownHandler)
    return () => document.removeEventListener('keydown', keydownHandler)
  })

  const powerHandler = e => {
    try {
      const command = e.currentTarget.getAttribute('data-value')
      axios.post('/power/park', {
        command,
      })
    } catch (err) {
      console.error(err)
    }
  }

  return !isVisible ? null : (
    <div className="modal-background" onClick={onClose}>
      <div
        className={`modal-body alert ${isVisible ? 'open' : 'closed'}`}
        onClick={e => e.stopPropagation()}
      >
        <div className="modal-header">
          <div className="close-logo" onClick={onClose}>
            <Close />
          </div>
        </div>
        <div className="alert-body">
          <Attention />

          <div className="alert-title">
            Вы действительно хотите перезапустить все залы?
          </div>
          <div className="alert-buttons">
            <button
              className="alert-button alert-cancel"
              onClick={onClose}
              title="Отменить"
              type="button"
            >
              <div className="logo-cancel">
                <Cancel />
              </div>
              Отмена
            </button>
            <button
              className="alert-button alert-restart"
              onClick={powerHandler}
              data-value="restart"
              title="Перезапустить"
              type="button"
            >
              <div className="logo-restart">
                <Restart />
              </div>
              Перезапустить
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
