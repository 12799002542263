import React, { useEffect, useState } from 'react'
import { ReactComponent as Close } from './icons/close.svg'
import ZoneStatusInfo from './ZoneStatusInfo'
import './styles/statusZonesPopUp.scss'
import {
  __UNDER_FLOOR__,
  __FIRST_FLOOR__,
  __SECOND_FLOOR__,
  __THIRD_FLOOR__,
} from './utility/globals.ts'

function StatusPopUp({
  isVisible = false,
  underFloor,
  firstFloor,
  secondFloor,
  thirdFloor,
  onClose,
}) {
  const keydownHandler = ({ key }) => {
    switch (key) {
      case 'Escape':
        onClose()
        break
      default:
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', keydownHandler)
    return () => document.removeEventListener('keydown', keydownHandler)
  })

  return !isVisible ? null : (
    <div className="modal-background" onClick={onClose}>
      <div
        className={`modal-body status ${isVisible ? 'open' : 'closed'}`}
        onClick={e => e.stopPropagation()}
      >
        <div className="modal-header">
          Статусы залов
          <div className="close-logo" onClick={onClose}>
            <Close />
          </div>
        </div>
        <div className="main-zones-wrapper">
          <div className="main-zone-panel-container">
            <div className="main-zone-panel-name">{__UNDER_FLOOR__}</div>
            <div className="zones-array">
              {underFloor.map(zone => (
                <ZoneStatusInfo key={zone.id} zone={zone} />
              ))}
            </div>
          </div>
          <div className="main-zone-panel-container">
            <div className="main-zone-panel-name">{__FIRST_FLOOR__}</div>
            <div className="zones-array">
              {firstFloor.map(zone => (
                <ZoneStatusInfo key={zone.id} zone={zone} />
              ))}
            </div>
          </div>
          <div className="main-zone-panel-container">
            <div className="main-zone-panel-name">{__SECOND_FLOOR__}</div>
            <div className="zones-array">
              {secondFloor.map(zone => (
                <ZoneStatusInfo key={zone.id} zone={zone} />
              ))}
            </div>
          </div>
          <div className="main-zone-panel-container">
            <div className="main-zone-panel-name">{__THIRD_FLOOR__}</div>
            <div className="zones-array">
              {thirdFloor.map(zone => (
                <ZoneStatusInfo key={zone.id} zone={zone} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(StatusPopUp)
