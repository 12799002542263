// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.MuiTextField-root,
.MuiFormLabel-root,
.MuiInputBase-root,
.MuiMultiSectionDigitalClockSection-item,
.MuiButtonBase-root {
  font-family: Norms !important;
}

.MuiIconButton-root {
  padding-bottom: 10px !important;
}

.MuiInputBase-root.MuiOutlinedInput-root {
  background-color: transparent !important;
  font-size: 20px !important;
}

.MuiSvgIcon-root {
  fill: #707073 !important;
}

.MuiInputLabel-root.Mui-focused,
.MuiFormLabel-root.MuiInputLabel-root,
.MuiList-root,
.MuiOutlinedInput-root,
.MuiButtonBase-root.MuiButton-root {
  color: #707073 !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.MuiMultiSectionDigitalClockSection-root {
  width: 71px !important;
  scrollbar-width: none !important; /* для Firefox */
  -ms-overflow-style: none !important; /* для IE и Edge */
}
.MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar {
  width: 0 !important;
}
@media (max-width: 768px) {
  .MuiMultiSectionDigitalClockSection-root {
    width: 55px !important;
  }
}

.MuiMultiSectionDigitalClockSection-item {
  width: 100% !important;
  margin-inline: 0 !important;
}

.Mui-selected {
  background-color: #707073 !important;
  color: white !important;
}

.timepicker-label {
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .timepicker-label {
    font-size: 12px;
    margin-bottom: 5px;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/timepicker.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;;;;;EAKE,6BAAA;AAEF;;AAAA;EACE,+BAAA;AAGF;;AADA;EACE,wCAAA;EACA,0BAAA;AAIF;;AAFA;EACE,wBAAA;AAKF;;AAHA;;;;;EAKE,yBAAA;AAMF;;AAHA;EACE,uBAAA;AAMF;;AAHA;EACE,sBAAA;EAKA,gCAAA,EAAA,gBAAA;EACA,mCAAA,EAAA,kBAAA;AAEF;AANE;EACE,mBAAA;AAQJ;AAHE;EATF;IAUI,sBAAA;EAMF;AACF;;AAHA;EACE,sBAAA;EACA,2BAAA;AAMF;;AAHA;EACE,oCAAA;EACA,uBAAA;AAMF;;AAHA;EACE,mBAAA;AAMF;AALE;EAFF;IAGI,eAAA;IACA,kBAAA;EAQF;AACF","sourcesContent":[".MuiTextField-root,\n.MuiFormLabel-root,\n.MuiInputBase-root,\n.MuiMultiSectionDigitalClockSection-item,\n.MuiButtonBase-root {\n  font-family: Norms !important;\n}\n.MuiIconButton-root {\n  padding-bottom: 10px !important;\n}\n.MuiInputBase-root.MuiOutlinedInput-root {\n  background-color: transparent !important;\n  font-size: 20px !important;\n}\n.MuiSvgIcon-root {\n  fill: #707073 !important;\n}\n.MuiInputLabel-root.Mui-focused,\n.MuiFormLabel-root.MuiInputLabel-root,\n.MuiList-root,\n.MuiOutlinedInput-root,\n.MuiButtonBase-root.MuiButton-root {\n  color: #707073 !important;\n}\n\n.MuiOutlinedInput-notchedOutline {\n  border: none !important;\n}\n\n.MuiMultiSectionDigitalClockSection-root {\n  width: 71px !important;\n\n  &::-webkit-scrollbar {\n    width: 0 !important;\n  }\n  scrollbar-width: none !important; /* для Firefox */\n  -ms-overflow-style: none !important; /* для IE и Edge */\n\n  @media (max-width: 768px) {\n    width: 55px !important;\n  }\n}\n\n.MuiMultiSectionDigitalClockSection-item {\n  width: 100% !important;\n  margin-inline: 0 !important;\n}\n\n.Mui-selected {\n  background-color: #707073 !important;\n  color: white !important;\n}\n\n.timepicker-label {\n  margin-bottom: 10px;\n  @media (max-width: 768px) {\n    font-size: 12px;\n    margin-bottom: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
