import axios from 'axios'
import React, { useState, useEffect } from 'react'
import './styles/panel.scss'
import { ReactComponent as MuseumLogo } from './icons/museum.svg'
import { ReactComponent as PowerWhiteLogo } from './icons/power-white.svg'
import { ReactComponent as Off } from './icons/off.svg'
import { ReactComponent as ClockLogo } from './icons/clock.svg'
import { ReactComponent as SettingsLogo } from './icons/settings.svg'
import Schedule from './SchedulePopUp'
import dayjs from 'dayjs'

export default function Panel() {
  const [isModalSchedule, setModalSchedule] = useState(false)
  const savedTimeStart = localStorage.getItem('timeStart')
  const savedTimeEnd = localStorage.getItem('timeEnd')
  let initialTimeEnd
  const [formattedTimeStart, setFormattedTimeStart] = useState('')
  const [formattedTimeEnd, setFormattedTimeEnd] = useState('')

  const initialTimeStart = savedTimeStart ? dayjs(savedTimeStart) : dayjs()

  //чтобы при первом запуске отображалось время старта и время окончания +8 к старту
  if (savedTimeEnd) {
    initialTimeEnd = dayjs(savedTimeEnd)
  } else {
    initialTimeEnd = initialTimeStart.add(8, 'hour')
    localStorage.setItem('timeEnd', initialTimeEnd.toISOString())
  }

  const [timeStart, setTimeStart] = useState(initialTimeStart)
  const [timeEnd, setTimeEnd] = useState(initialTimeEnd)

  //чтобы на фронте норм отображалось тк время в непонятном формате
  useEffect(() => {
    const formattedStart = timeStart ? dayjs(timeStart).format('HH:mm') : ''
    const formattedEnd = timeEnd ? dayjs(timeEnd).format('HH:mm') : ''
    setFormattedTimeStart(formattedStart)
    setFormattedTimeEnd(formattedEnd)
  }, [timeStart, timeEnd])

  //вкл выкл
  const powerHandler = e => {
    try {
      const command = e.currentTarget.getAttribute('data-value')
      axios.post('/power/park', {
        command,
      })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <div className="main-title">ПАНЕЛЬ УПРАВЛЕНИЯ</div>
      <div className="manage-container">
        <div className="manage-container__item zones-status">
          <div className="manage-container__item__title zones-status">
            <div className="logo-museum">
              <MuseumLogo />
            </div>
            Все залы
          </div>
          <div className="manage-container__item-power-buttons">
            <div className="buttons">
              {' '}
              <button
                onClick={powerHandler}
                type="button"
                className="button_green"
                data-value="on"
                title="Включить"
              >
                <PowerWhiteLogo />
              </button>
              <button
                onClick={powerHandler}
                type="button"
                className="button_red"
                data-value="off"
                title="Выключить"
              >
                <Off />
              </button>
            </div>
          </div>
        </div>
        <div className="manage-container__item shedule">
          <div className="manage-container__item__title shedule">
            <div className="logo-shedule">
              <ClockLogo />
            </div>
            Расписание
          </div>
          <div
            className="manage-container__item-shedule__wrapper"
            onClick={() => setModalSchedule(true)}
          >
            <div className="manage-container__item-shedule__time">
              {formattedTimeStart} - {formattedTimeEnd}
            </div>
            <div className="logo-settings">
              <SettingsLogo />
            </div>
          </div>
          <Schedule
            isVisible={isModalSchedule}
            onClose={() => setModalSchedule(false)}
            timeStart={timeStart}
            timeEnd={timeEnd}
            savedTimeStart={savedTimeStart}
            savedTimeEnd={savedTimeEnd}
            setTimeStart={setTimeStart}
            setTimeEnd={setTimeEnd}
          />
        </div>
      </div>
    </>
  )
}
