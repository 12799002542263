import './styles/app.scss'
import React, { useEffect, useState } from 'react'
import MainPage from './MainPage'
import Header from './Header'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { getZonesStatusThunk } from './redux/slices/floorsSlice'

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getZonesStatusThunk())
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(getZonesStatusThunk())
    }, 15000)
    return () => clearInterval(intervalId)
  }, [])

  const zonesArray = useSelector(store => store.floors)

  return (
    <>
      <div className="app">
        <div className="wrapper">
          <Header
            underFloor={zonesArray.underFloor}
            firstFloor={zonesArray.firstFloor}
            secondFloor={zonesArray.secondFloor}
            thirdFloor={zonesArray.thirdFloor}
          />
          <MainPage
            underFloor={zonesArray.underFloor}
            firstFloor={zonesArray.firstFloor}
            secondFloor={zonesArray.secondFloor}
            thirdFloor={zonesArray.thirdFloor}
          />
        </div>
      </div>
    </>
  )
}

export default App
