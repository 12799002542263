import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { ReactComponent as Forward } from './icons/forward.svg'
import { ReactComponent as Backward } from './icons/backward.svg'
import { ReactComponent as Play } from './icons/play.svg'

export default function ContentButtons({ id, button }) {
  const contentHandler = e => {
    try {
      const command = e.currentTarget.getAttribute('data-value')
      console.log(button.url, ' -> ', id, command, 'id button ->', button.id)
      axios.post(button.url, { zone: id, command, id: button.id })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className="zone-buttons-container content-buttons">
      <button
        onClick={contentHandler}
        type="button"
        className="content-button"
        data-value="backward"
        title="Назад"
      >
        <Backward />
      </button>
      <button
        onClick={contentHandler}
        type="button"
        className="content-button"
        data-value="pause"
        title="Пауза"
      >
        <Play />
      </button>
      <button
        onClick={contentHandler}
        type="button"
        className="content-button"
        data-value="forward"
        title="Вперед"
      >
        <Forward />
      </button>
    </div>
  )
}
