// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 64px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .main-container {
    gap: 40px;
  }
}

.main-zone-wrapper {
  display: flex;
  gap: 50px;
  flex-direction: column;
}
@media (max-width: 768px) {
  .main-zone-wrapper {
    width: 100%;
  }
}

.main-zone-container {
  display: flex;
  gap: 15px;
  flex-direction: column;
}
@media (max-width: 768px) {
  .main-zone-container {
    width: 100%;
  }
}

.main-zone-title {
  font-weight: 400;
  font-size: 30px;
  line-height: 150%;
  color: var(--white);
}
@media (max-width: 768px) {
  .main-zone-title {
    font-size: 20px;
  }
}

.zones-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}
@media (max-width: 768px) {
  .zones-container {
    flex-direction: column;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/mainPage.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,cAAA;AACF;AACE;EAVF;IAWI,SAAA;EAEF;AACF;;AACA;EACE,aAAA;EACA,SAAA;EACA,sBAAA;AAEF;AAAE;EALF;IAMI,WAAA;EAGF;AACF;;AAAA;EACE,aAAA;EACA,SAAA;EACA,sBAAA;AAGF;AADE;EALF;IAMI,WAAA;EAIF;AACF;;AADA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;AAIF;AAFE;EANF;IAOI,eAAA;EAKF;AACF;;AAFA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,mBAAA;EACA,2BAAA;EACA,SAAA;AAKF;AAFE;EATF;IAUI,sBAAA;EAKF;AACF","sourcesContent":[".main-container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-content: center;\n  justify-content: center;\n  align-items: center;\n  gap: 64px;\n  margin: 0 auto;\n\n  @media (max-width: 768px) {\n    gap: 40px;\n  }\n}\n\n.main-zone-wrapper {\n  display: flex;\n  gap: 50px;\n  flex-direction: column;\n  // mobile\n  @media (max-width: 768px) {\n    width: 100%;\n  }\n}\n\n.main-zone-container {\n  display: flex;\n  gap: 15px;\n  flex-direction: column;\n  // mobile\n  @media (max-width: 768px) {\n    width: 100%;\n  }\n}\n\n.main-zone-title {\n  font-weight: 400;\n  font-size: 30px;\n  line-height: 150%;\n  color: var(--white);\n  // mobile\n  @media (max-width: 768px) {\n    font-size: 20px;\n  }\n}\n\n.zones-container {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 24px;\n\n  // mobile\n  @media (max-width: 768px) {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
