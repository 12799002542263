import React from 'react'
import './styles/statusZonesPopUp.scss'
import SingleZoneStatus from './SingleZoneStatus'
import determineOverallStatus from './utility/determineOverallStatus'
import { statusToLogo } from './utility/statusToLogo'

export default function ZoneStatusInfo({ zone }) {
  let classNameStatus = determineOverallStatus(zone)
  let logoStatus = statusToLogo(classNameStatus)
  
  if (zone.innerZones) {
    return (
      <div className="zoneWithInnerZones">
        <div className="zoneWithInnerZones-title">{zone.name}</div>
        <div className="innerZonesArray">
          {zone.innerZones.map(innerZone => (
            <SingleZoneStatus
              key={innerZone.name}
              name={innerZone.name}
              status={innerZone.status}
              isChild={true}
              withText={true}
              classNameStatus={classNameStatus}
              logoStatus={logoStatus}
            />
          ))}
        </div>
      </div>
    )
  }

  if (zone.status) {
    return (
      <SingleZoneStatus
        name={zone.name}
        status={zone.status}
        isChild={false}
        withText={true}
        classNameStatus={classNameStatus}
        logoStatus={logoStatus}
      />
    )
  }

  return null
}
