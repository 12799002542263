import { useState, useEffect } from 'react'
import './styles/mainPage.scss'
import './styles/buttons.scss'
import axios from 'axios'
import Zone from './Zone'
import Panel from './Panel'
import {
  __UNDER_FLOOR__,
  __FIRST_FLOOR__,
  __SECOND_FLOOR__,
  __THIRD_FLOOR__,
} from './utility/globals.ts'

function MainPage({ underFloor, firstFloor, secondFloor, thirdFloor }) {
  return (
    <>
      <div className="main-container">
        <Panel />
        <div className="main-zone-wrapper">
          <div className="main-zone-container">
            <div className="main-zone-title">{__UNDER_FLOOR__}</div>
            <div className="zones-container">
              {underFloor.map(zone => (
                <Zone key={zone.id} zone={zone} />
              ))}
            </div>
          </div>
          <div className="main-zone-container">
            <div className="main-zone-title">{__FIRST_FLOOR__}</div>
            <div className="zones-container">
              {firstFloor.map(zone => (
                <Zone key={zone.id} zone={zone} />
              ))}
            </div>
          </div>
          <div className="main-zone-container">
            <div className="main-zone-title">{__SECOND_FLOOR__}</div>
            <div className="zones-container">
              {secondFloor.map(zone => (
                <Zone key={zone.id} zone={zone} />
              ))}
            </div>
          </div>
          <div className="main-zone-container">
            <div className="main-zone-title">{__THIRD_FLOOR__}</div>
            <div className="zones-container">
              {thirdFloor.map(zone => (
                <Zone key={zone.id} zone={zone} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MainPage
