import React, { useEffect, useState } from 'react'
import axios from 'axios'
import MuiSelect from './MuiSelect'

export default function ThemesDropdown({ id, button }) {
  return (
    <div className="select-place">
      <MuiSelect id={id} button={button} />
    </div>
  )
}
