import React, { useEffect } from 'react'
import { ReactComponent as Close } from './icons/close.svg'
import { ReactComponent as Info } from './icons/info.svg'
import ZoneStatusValues from './ZoneStatusValuesComponent.jsx'
import './styles/zonePopUpSingle.scss'
import ZoneButtonsContainer from './ZoneButtonsContainer.jsx'

function ZonePopUpSingle({ isVisible = false, zone, onClose }) {
  const keydownHandler = ({ key }) => {
    switch (key) {
      case 'Escape':
        onClose()
        break
      default:
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', keydownHandler)
    return () => document.removeEventListener('keydown', keydownHandler)
  })

  return !isVisible ? null : (
    <div className="modal-background" onClick={onClose}>
      <div
        className={`modal-body single-zone-modal ${isVisible ? 'open' : 'closed'}`}
        onClick={e => e.stopPropagation()}
      >
        <div className="modal-header">
          {zone.name}
          <div className="close-logo" onClick={onClose}>
            <Close />
          </div>
        </div>
        <div className="scroll">
          <div className="zone-panel-container zone-panel-container-status">
            <div className="zone-panel-name">
              <div className="panel-logo">
                <Info />
              </div>
              Статус
            </div>
            {zone.status && (
              <div className="zonePopUp-status-container">
                <ZoneStatusValues statusObject={zone.status} />
              </div>
            )}
          </div>
          {zone.buttons &&
            zone.buttons.map(button => (
              <ZoneButtonsContainer id={zone.id} button={button} />
            ))}
        </div>
      </div>
    </div>
  )
}

export default React.memo(ZonePopUpSingle)
