import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

// перешли на иконки
// const translations = {
//   pc: 'ПК',
//   pj: 'Проектор',
//   camera: 'Камера',
//   player: 'Плеер',
//   controller: 'Контроллер',
//   display: 'Дисплей',
//   rfid: 'RFID',
//   phone: 'Телефон',
//   lidar: 'Lidar',
// }

// const translateStatusKey = key => {
//   // Разбиваем ключ на основную часть и суффикс
//   const [baseKey, suffix] = key.split('_')

//   // Переводим основную часть и добавляем суффикс
//   const translatedBaseKey = translations[baseKey] || baseKey
//   return suffix ? `${translatedBaseKey} ${suffix}` : translatedBaseKey
// }

// const translateStatusValue = value => {
//   const valueTranslations = {
//     200: 'Включено',
//     521: 'Выключено',
//     520: 'Ошибка',
//   }

//   return valueTranslations[value] || value
// }

// const translateStatus = status => {
//   return Object.keys(status).reduce((acc, key) => {
//     acc[translateStatusKey(key)] = translateStatusValue(status[key])
//     return acc
//   }, {})
// }

const floors = createSlice({
  name: 'floors',
  initialState: {
    underFloor: [
      {
        id: 'info',
        name: 'Информационные экраны',
        status: {
          pc_1: 520,
          display_1: 200,
          display_2: 521,
        },
        buttons: [
          { url: '/power/zone', id: 'power', name: 'Питание' },
          { url: '/power/pc', id: 'pc_1', name: 'ПК' },
          {
            url: '/restart/content',
            id: 'content',
            name: 'Перезагрузка контента',
          },
          {
            url: '/restart/app',
            id: 'app',
            name: 'Перезагрузка приложения',
          },
          { url: '/sound/zone', id: 'volume', name: 'Громкость' },
        ],
      },
      {
        id: 'photo',
        name: 'Стойка печати фото',
        status: {
          pc_1: 200,
        },
        buttons: [
          { url: '/power/zone', id: 'power', name: 'Питание' },
          { url: '/power/pc', id: 'pc_1', name: 'ПК' },
          {
            url: '/restart/app',
            id: 'app',
            name: 'Перезагрузка приложения',
          },
        ],
      },
      {
        id: 'portrait',
        name: 'Ожившие портреты',
        innerZones: [
          {
            id: 'portraitOne',
            name: 'Оживший портрет 1',
            status: {
              controller_1: 521,
              rfid_1: 521,
              display_1: 521,
              player_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/restart/player',
                id: 'player_1',
                name: 'Перезагрузка медиаплеера',
              },
              {
                url: '/restart/controller',
                id: 'controller_1',
                name: 'Перезагрузка контроллера',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
          {
            id: 'portraitTwo',
            name: 'Оживший портрет 2',
            status: {
              controller_1: 521,
              rfid_1: 521,
              display_1: 521,
              player_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/restart/player',
                id: 'player_1',
                name: 'Перезагрузка медиаплеера',
              },
              {
                url: '/restart/controller',
                id: 'controller_1',
                name: 'Перезагрузка контроллера',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
          {
            id: 'portraitThree',
            name: 'Оживший портрет 3',
            status: {
              controller_1: 521,
              rfid_1: 521,
              display_1: 521,
              player_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/restart/player',
                id: 'player_1',
                name: 'Перезагрузка медиаплеера',
              },
              {
                url: '/restart/controller',
                id: 'controller_1',
                name: 'Перезагрузка контроллера',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
          {
            id: 'portraitFour',
            name: 'Оживший портрет 4',
            status: {
              controller_1: 521,
              rfid_1: 521,
              display_1: 521,
              player_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/restart/player',
                id: 'player_1',
                name: 'Перезагрузка медиаплеера',
              },
              {
                url: '/restart/controller',
                id: 'controller_1',
                name: 'Перезагрузка контроллера',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
          {
            id: 'portraitFive',
            name: 'Оживший портрет 5',
            status: {
              controller_1: 521,
              rfid_1: 521,
              display_1: 521,
              player_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/restart/player',
                id: 'player_1',
                name: 'Перезагрузка медиаплеера',
              },
              {
                url: '/restart/controller',
                id: 'controller_1',
                name: 'Перезагрузка контроллера',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
        ],
      },
      {
        id: 'shop',
        name: 'Лавка чудес',
        status: {
          pc_1: 521,
          pj_1: 521,
          pj_2: 521,
          pj_3: 521,
          pj_4: 521,
          lidar_1: 521,
        },
        buttons: [
          { url: '/power/zone', id: 'power', name: 'Питание' },
          {
            url: '/power/pc',
            id: 'pc_1',
            name: 'ПК',
          },
          {
            url: '/power/projector',
            id: 'pj_1',
            name: 'Проектор 1',
          },
          {
            url: '/power/projector',
            id: 'pj_2',
            name: 'Проектор 2',
          },
          {
            url: '/power/projector',
            id: 'pj_3',
            name: 'Проектор 3',
          },
          {
            url: '/power/projector',
            id: 'pj_4',
            name: 'Проектор 4',
          },
          {
            url: '/power/lidar',
            id: 'lidar_1',
            name: 'Lidar',
          },
          {
            url: '/restart/content',
            id: 'content',
            name: 'Перезагрузка контента',
          },
          {
            url: '/restart/app',
            id: 'app',
            name: 'Перезагрузка приложения',
          },
          {
            url: '/light/mode',
            id: 'mode',
            name: 'Режим',
            themes: [
              { id: 'show', name: 'Шоу' },
              { id: 'wait', name: 'Ожидание' },
              { id: 'nature', name: 'Природа' },
            ],
          },
          { url: '/sound/zone', id: 'volume', name: 'Громкость' },
        ],
      },
    ],
    firstFloor: [
      {
        id: 'flashlight',
        name: 'Волшебный фонарь',
        status: {
          pc_1: 521,
          rfid_1: 521,
          rfid_2: 521,
          rfid_3: 521,
          camera_1: 521,
          camera_2: 521,
          pj_1: 521,
        },
        buttons: [
          { url: '/power/zone', id: 'power', name: 'Питание' },
          {
            url: '/power/pc',
            id: 'pc_1',
            name: 'ПК',
          },
          {
            url: '/power/projector',
            id: 'pj_1',
            name: 'Проектор 1',
          },
          {
            url: '/power/camera',
            id: 'camera_1',
            name: 'Камера 1',
          },
          {
            url: '/power/camera',
            id: 'camera_2',
            name: 'Камера 2',
          },
          {
            url: '/restart/content',
            id: 'content',
            name: 'Перезагрузка контента',
          },
          {
            url: '/restart/app',
            id: 'app',
            name: 'Перезагрузка приложения',
          },
          {
            url: '/light/mode',
            id: 'mode',
            name: 'Режим',
            themes: [
              { id: 'show', name: 'Шоу' },
              { id: 'wait', name: 'Ожидание' },
              { id: 'nature', name: 'Природа' },
            ],
          },
          { url: '/sound/zone', id: 'volume', name: 'Громкость' },
        ],
      },
      {
        id: 'den',
        name: 'Вертеп',
        innerZones: [
          {
            id: 'denDisplay',
            name: 'Вертеп с дисплеем',
            status: {
              controller_1: 521,
              display_1: 521,
              player_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/restart/player',
                id: 'player_1',
                name: 'Перезагрузка медиаплеера',
              },
              {
                url: '/restart/controller',
                id: 'controller_1',
                name: 'Перезагрузка контроллера',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
          {
            id: 'denLamp',
            name: 'Вертеп с лампочкой',
            status: {
              rfid_1: 521,
            },
            buttons: [
              {
                url: '/light/lamp',
                id: 'lamp',
                name: 'Включить лампочку',
              },
            ],
          },
          {
            id: 'denSoundOne',
            name: 'Звуковой вертеп №1',
            status: {
              rfid_1: 521,
              player_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/restart/player',
                id: 'player_1',
                name: 'Перезагрузка медиаплеера',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
          {
            id: 'denSoundTwo',
            name: 'Звуковой вертеп №2',
            status: {
              rfid_1: 521,
              player_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/restart/player',
                id: 'player_1',
                name: 'Перезагрузка медиаплеера',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
        ],
      },
      {
        id: 'theatre',
        name: 'Бумажный театр',
        status: {
          pc_1: 521,
          pj_1: 521,
          rfid_1: 521,
          rfid_2: 521,
          rfid_3: 521,
          rfid_4: 521,
          rfid_5: 521,
          rfid_6: 521,
          rfid_7: 521,
          rfid_8: 521,
          rfid_9: 521,
          rfid_10: 521,
        },
        buttons: [
          { url: '/power/zone', id: 'power', name: 'Питание' },
          {
            url: '/power/pc',
            id: 'pc_1',
            name: 'ПК',
          },
          {
            url: '/power/projector',
            id: 'pj_1',
            name: 'Проектор 1',
          },
          {
            url: '/power/camera',
            id: 'camera_1',
            name: 'Камера 1',
          },
          {
            url: '/restart/content',
            id: 'content',
            name: 'Перезагрузка контента',
          },
          {
            url: '/restart/app',
            id: 'app',
            name: 'Перезагрузка приложения',
          },
          {
            url: '/light/mode',
            id: 'mode',
            name: 'Режим',
            themes: [
              { id: 'show', name: 'Шоу' },
              { id: 'wait', name: 'Ожидание' },
              { id: 'nature', name: 'Природа' },
            ],
          },
          { url: '/sound/zone', id: 'volume', name: 'Громкость' },
        ],
      },
      {
        id: 'raoyk',
        name: 'Театр Петрушки. Раёк',
        innerZones: [
          {
            id: 'raoykTheatre',
            name: 'Раёк',
            status: {
              controller_1: 521,
              rfid_1: 521,
              display_1: 521,
              camera_1: 521,
              player_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/power/camera',
                id: 'camera_1',
                name: 'Камера 1',
              },
              {
                url: '/restart/player',
                id: 'player_1',
                name: 'Перезагрузка медиаплеера',
              },
              {
                url: '/restart/controller',
                id: 'controller_1',
                name: 'Перезагрузка контроллера',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
          {
            id: 'smileOne',
            name: 'Распознование улыбки 1',
            status: {
              pc_1: 521,
              display_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/power/pc',
                id: 'pc_1',
                name: 'ПК',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              {
                url: '/restart/app',
                id: 'app',
                name: 'Перезагрузка приложения',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
          {
            id: 'smileTwo',
            name: 'Распознование улыбки 2',
            status: {
              pc_1: 521,
              display_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/power/pc',
                id: 'pc_1',
                name: 'ПК',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              {
                url: '/restart/app',
                id: 'app',
                name: 'Перезагрузка приложения',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
          {
            id: 'raoykSound',
            name: 'Фоновая озвучка',
            status: {
              player_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/restart/player',
                id: 'content',
                name: 'Перезагрузка медиаплеера',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              {
                url: '/content/playmode',
                id: 'playmode',
                name: 'Кнопки для контента "пауза", "назад", "вперед"',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
        ],
      },
    ],
    secondFloor: [
      {
        id: 'table',
        name: 'Чудо-стол',
        innerZones: [
          {
            id: 'tablePhoto',
            name: 'Фотомеханика',
            status: {
              pc_1: 521,
              rfid_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/power/pc',
                id: 'pc_1',
                name: 'ПК',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              {
                url: '/restart/app',
                id: 'app',
                name: 'Перезагрузка приложения',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
              { url: '/light/brightness', id: 'lamp', name: 'Свет' },
            ],
          },
          {
            id: 'tableSound',
            name: 'Фоновая озвучка',
            status: {
              player_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/restart/player',
                id: 'player_1',
                name: 'Перезагрузка медиаплеера',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              {
                url: '/content/playmode',
                id: 'playmode',
                name: 'Кнопки для контента "пауза", "назад", "вперед"',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
        ],
      },
      {
        id: 'puppets',
        name: 'Марионетки',
        innerZones: [
          {
            id: 'puppetsDolls',
            name: 'Марионетки',
            status: {
              controller_1: 521,
              controller_2: 521,
              controller_3: 521,
              controller_4: 521,
              controller_5: 521,
              controller_6: 521,
              controller_7: 521,
              controller_8: 521,
              controller_9: 521,
              controller_10: 521,
              display_1: 521,
              camera_1: 521,
              player_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/power/pc',
                id: 'pc_1',
                name: 'ПК',
              },
              {
                url: '/power/camera',
                id: 'camera_1',
                name: 'Камера 1',
              },
              {
                url: '/restart/controller',
                id: 'all',
                name: 'Перезагрузка контроллеров',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              {
                url: '/restart/app',
                id: 'app',
                name: 'Перезагрузка приложения',
              },
              {
                url: '/light/mode',
                id: 'mode',
                name: 'Режим',
                themes: [
                  { id: 'show', name: 'Шоу' },
                  { id: 'wait', name: 'Ожидание' },
                  { id: 'nature', name: 'Природа' },
                ],
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },

          {
            id: 'dollOne',
            name: 'Говорящая кукла 1',
            status: {
              rfid_1: 521,
              player_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/restart/player',
                id: 'player_1',
                name: 'Перезагрузка медиаплеера',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
          {
            id: 'dollTwo',
            name: 'Говорящая кукла 2',
            status: {
              rfid_1: 521,
              player_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/restart/player',
                id: 'player_1',
                name: 'Перезагрузка медиаплеера',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
          {
            id: 'dollThree',
            name: 'Говорящая кукла 3',
            status: {
              rfid_1: 521,
              player_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/restart/player',
                id: 'player_1',
                name: 'Перезагрузка медиаплеера',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
          {
            id: 'dollFour',
            name: 'Говорящая кукла 4',
            status: {
              rfid_1: 521,
              player_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/restart/player',
                id: 'player_1',
                name: 'Перезагрузка медиаплеера',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
          {
            id: 'dollFive',
            name: 'Говорящая кукла 5',
            status: {
              rfid_1: 521,
              player_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/restart/player',
                id: 'player_1',
                name: 'Перезагрузка медиаплеера',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
        ],
      },
      {
        id: 'tuz',
        name: 'Основание ТЮЗов',
        status: {
          pc_1: 521,
          rfid_1: 521,
        },
        buttons: [
          { url: '/power/zone', id: 'power', name: 'Питание' },
          {
            url: '/power/pc',
            id: 'pc_1',
            name: 'ПК',
          },
          {
            url: '/restart/content',
            id: 'content',
            name: 'Перезагрузка контента',
          },
          {
            url: '/restart/app',
            id: 'app',
            name: 'Перезагрузка приложения',
          },
          { url: '/sound/zone', id: 'volume', name: 'Громкость' },
        ],
      },
      {
        id: 'pop',
        name: 'Эстрада 60-70х',
        innerZones: [
          {
            id: 'popDisplay',
            name: 'Дисплеи в окнах',
            status: {
              display_1: 521,
              display_2: 521,
              display_3: 521,
              display_4: 521,
              display_5: 521,
              player_1: 521,
              player_2: 521,
              player_3: 521,
              player_4: 521,
              player_5: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/power/camera',
                id: 'camera_1',
                name: 'Камера 1',
              },
              {
                url: '/restart/player',
                id: 'all',
                name: 'Все плееры',
              },
              {
                url: '/restart/controller',
                id: 'controller_1',
                name: 'Перезагрузка контроллера',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              {
                url: '/light/mode',
                id: 'mode',
                name: 'Режим',
                themes: [
                  { id: 'show', name: 'Шоу' },
                  { id: 'wait', name: 'Ожидание' },
                  { id: 'nature', name: 'Природа' },
                ],
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
          {
            id: 'popSound',
            name: 'Фоновый звук',
            status: {
              player_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/restart/player',
                id: 'content',
                name: 'Перезагрузка медиаплеера',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              {
                url: '/content/playmode',
                id: 'playmode',
                name: 'Кнопки для контента "пауза", "назад", "вперед"',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
        ],
      },
      {
        id: 'phone',
        name: 'Современные ТЮЗы',
        innerZones: [
          {
            id: 'phoneOne',
            name: 'Телефон 1',
            status: {
              phone_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/power/phone',
                id: 'phone_1',
                name: 'Телефон',
              },
            ],
          },
          {
            id: 'phoneTwo',
            name: 'Телефон 2',
            status: {
              phone_2: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/power/phone',
                id: 'phone_1',
                name: 'Телефон',
              },
            ],
          },
          {
            id: 'phoneThree',
            name: 'Телефон 3',
            status: {
              phone_3: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/power/phone',
                id: 'phone_1',
                name: 'Телефон',
              },
            ],
          },
          {
            id: 'phoneFour',
            name: 'Телефон 4',
            status: {
              phone_4: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/power/phone',
                id: 'phone_1',
                name: 'Телефон',
              },
            ],
          },
          {
            id: 'phoneFive',
            name: 'Телефон 5',
            status: {
              phone_5: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/power/phone',
                id: 'phone_1',
                name: 'Телефон',
              },
            ],
          },
          {
            id: 'phoneSound',
            name: 'Фоновая озвучка',
            status: {
              player_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/restart/player',
                id: 'content',
                name: 'Перезагрузка медиаплеера',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              {
                url: '/content/playmode',
                id: 'playmode',
                name: 'Кнопки для контента "пауза", "назад", "вперед"',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
        ],
      },
      {
        id: 'cane',
        name: 'Тростевые куклы',
        innerZones: [
          {
            id: 'cane',
            name: 'Тростевые куклы',
            status: {
              pc_1: 521,
              rfid_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              { url: '/power/pc', id: 'pc_1', name: 'ПК' },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              {
                url: '/restart/app',
                id: 'app',
                name: 'Перезагрузка приложения',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
          {
            id: 'caneSound',
            name: 'Фоновая озвучка',
            status: {
              player_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/restart/player',
                id: 'player_1',
                name: 'Перезагрузка медиаплеера',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              {
                url: '/content/playmode',
                id: 'playmode',
                name: 'Кнопки для контента "пауза", "назад", "вперед"',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
        ],
      },
    ],
    thirdFloor: [
      {
        id: 'mechanics',
        name: 'Закулисная машинерия',
        status: {
          player_1: 521,
        },
        buttons: [
          { url: '/power/zone', id: 'power', name: 'Питание' },
          {
            url: '/restart/player',
            id: 'player_1',
            name: 'Перезагрузка медиаплеера',
          },
          {
            url: '/restart/content',
            id: 'content',
            name: 'Перезагрузка контента',
          },
          {
            url: '/content/playmode',
            id: 'playmode',
            name: 'Кнопки для контента "пауза", "назад", "вперед"',
          },
          { url: '/sound/zone', id: 'volume', name: 'Громкость' },
        ],
      },
      {
        id: 'doll',
        name: 'Мастерская кукольника',
        innerZones: [
          {
            id: 'restorer',
            name: 'Стань реставратором',
            status: {
              pc_1: 521,
              rfid_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              { url: '/power/pc', id: 'pc_1', name: 'ПК' },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              {
                url: '/restart/app',
                id: 'app',
                name: 'Перезагрузка приложения',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
          {
            id: 'dollMaster',
            name: 'Стань кукольником',
            status: {
              pc_1: 521,
              controller_1: 521,
              controller_2: 521,
              controller_3: 521,
              display_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/power/pc',
                id: 'pc_1',
                name: 'ПК',
              },
              {
                url: '/restart/controller',
                id: 'all',
                name: 'Перезагрузка контроллеров',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              {
                url: '/restart/app',
                id: 'app',
                name: 'Перезагрузка приложения',
              },
              {
                url: '/light/mode',
                id: 'mode',
                name: 'Режим',
                themes: [
                  { id: 'show', name: 'Шоу' },
                  { id: 'wait', name: 'Ожидание' },
                  { id: 'nature', name: 'Природа' },
                ],
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
          {
            id: 'restorerSound',
            name: 'Звуковое сопровождение',
            status: {
              camera_1: 521,
              player_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/power/camera',
                id: 'camera_1',
                name: 'Камера 1',
              },
              {
                url: '/restart/player',
                id: 'player_1',
                name: 'Перезагрузка медиаплеера',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              {
                url: '/content/playmode',
                id: 'playmode',
                name: 'Кнопки для контента "пауза", "назад", "вперед"',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
        ],
      },
      {
        id: 'model',
        name: 'Большой макет',
        innerZones: [
          {
            id: 'layout',
            name: 'Макет',
            status: {
              pj_1: 521,
              rfid_1: 521,
              player_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/power/projector',
                id: 'pj_1',
                name: 'Проектор',
              },
              {
                url: '/restart/player',
                id: 'player_1',
                name: 'Перезагрузка медиаплеера',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              {
                url: '/light/mode',
                id: 'mode',
                name: 'Режим',
                themes: [
                  { id: 'show', name: 'Шоу' },
                  { id: 'wait', name: 'Ожидание' },
                  { id: 'nature', name: 'Природа' },
                ],
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
          {
            id: 'modelSound',
            name: 'Фоновая озвучка',
            status: {
              camera_1: 521,
              player_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/power/camera',
                id: 'camera_1',
                name: 'Камера 1',
              },
              {
                url: '/restart/player',
                id: 'player_1',
                name: 'Перезагрузка медиаплеера',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              {
                url: '/content/playmode',
                id: 'playmode',
                name: 'Кнопки для контента "пауза", "назад", "вперед"',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
        ],
      },
      {
        id: 'labyrinth',
        name: 'Мастерская кукольника. Лабиринт',
        innerZones: [
          {
            id: 'labyrinthOne',
            name: 'Лабиринт Картин 1',
            status: {
              pc_1: 521,
              rfid_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              { url: '/power/pc', id: 'pc_1', name: 'ПК' },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              {
                url: '/restart/app',
                id: 'app',
                name: 'Перезагрузка приложения',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
          {
            id: 'labyrinthTwo',
            name: 'Лабиринт Картин 2',
            status: {
              pc_1: 521,
              rfid_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              { url: '/power/pc', id: 'pc_1', name: 'ПК' },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              {
                url: '/restart/app',
                id: 'app',
                name: 'Перезагрузка приложения',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
          {
            id: 'labyrinthThree',
            name: 'Лабиринт Картин 3',
            status: {
              pc_1: 521,
              rfid_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              { url: '/power/pc', id: 'pc_1', name: 'ПК' },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              {
                url: '/restart/app',
                id: 'app',
                name: 'Перезагрузка приложения',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
          {
            id: 'labyrinthFour',
            name: 'Лабиринт Картин 4',
            status: {
              pc_1: 521,
              rfid_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              { url: '/power/pc', id: 'pc_1', name: 'ПК' },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              {
                url: '/restart/app',
                id: 'app',
                name: 'Перезагрузка приложения',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
          {
            id: 'labyrinthFive',
            name: 'Лабиринт Картин 5',
            status: {
              pc_1: 521,
              rfid_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              { url: '/power/pc', id: 'pc_1', name: 'ПК' },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              {
                url: '/restart/app',
                id: 'app',
                name: 'Перезагрузка приложения',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
          {
            id: 'labyrinthSound',
            name: 'Фоновая озвучка',
            status: {
              camera_1: 521,
              player_1: 521,
            },
            buttons: [
              { url: '/power/zone', id: 'power', name: 'Питание' },
              {
                url: '/power/camera',
                id: 'camera_1',
                name: 'Камера 1',
              },
              {
                url: '/restart/player',
                id: 'player_1',
                name: 'Перезагрузка медиаплеера',
              },
              {
                url: '/restart/content',
                id: 'content',
                name: 'Перезагрузка контента',
              },
              {
                url: '/content/playmode',
                id: 'playmode',
                name: 'Кнопки для контента "пауза", "назад", "вперед"',
              },
              { url: '/sound/zone', id: 'volume', name: 'Громкость' },
            ],
          },
        ],
      },
      {
        id: 'final',
        name: 'Пост-шоу. Финал путешествия',
        status: {
          pc_1: 521,
          pj_1: 521,
          pj_2: 521,
          pj_3: 521,
          rfid_1: 521,
          rfid_2: 521,
          rfid_3: 521,
          rfid_4: 521,
          rfid_5: 521,
          rfid_6: 521,
          rfid_7: 521,
          rfid_8: 521,
          rfid_9: 521,
          rfid_10: 521,
          camera_1: 521,
        },
        buttons: [
          { url: '/power/zone', id: 'power', name: 'Питание' },
          {
            url: '/power/pc',
            id: 'pc_1',
            name: 'Перезагрузка медиаплеера',
          },
          {
            url: '/power/projector',
            id: 'pj_1',
            name: 'Проектор 1',
          },
          {
            url: '/power/projector',
            id: 'pj_2',
            name: 'Проектор 2',
          },
          {
            url: '/power/projector',
            id: 'pj_3',
            name: 'Проектор 3',
          },
          {
            url: '/power/camera',
            id: 'camera_1',
            name: 'Камера 1',
          },
          {
            url: '/restart/controller',
            id: 'all',
            name: 'Перезагрузка контроллеров',
          },
          {
            url: '/restart/content',
            id: 'content',
            name: 'Перезагрузка контента',
          },
          {
            url: '/restart/app',
            id: 'app',
            name: 'Перезагрузка приложения',
          },
          {
            url: '/light/mode',
            id: 'mode',
            name: 'Режим',
            themes: [
              { id: 'show', name: 'Шоу' },
              { id: 'wait', name: 'Ожидание' },
              { id: 'nature', name: 'Природа' },
            ],
          },
          { url: '/sound/zone', id: 'volume', name: 'Громкость' },
        ],
      },
      {
        id: 'toilets',
        name: 'Озвучка санузлов',
        status: {
          player_1: 521,
        },
        buttons: [
          { url: '/power/zone', id: 'power', name: 'Питание' },
          {
            url: '/restart/player',
            id: 'player_1',
            name: 'Перезагрузка медиаплеера',
          },
          {
            url: '/restart/content',
            id: 'content',
            name: 'Перезагрузка контента',
          },
          {
            url: '/content/playmode',
            id: 'playmode',
            name: 'Кнопки для контента "пауза", "назад", "вперед"',
          },
          { url: '/sound/zone', id: 'volume', name: 'Громкость' },
        ],
      },
    ],
  },
  reducers: {
    setZonesStatus: (state, action) => {
      const zonesFromBackend = action.payload

      const updateZoneStatus = (zone, backendZone) => {
        // if (backendZone.status) {
        //   zone.status = translateStatus(backendZone.status)
        // }

        if (backendZone.innerZones && zone.innerZones) {
          for (const innerZone of zone.innerZones) {
            const matchingBackendInnerZone = backendZone.innerZones.find(
              backZone => backZone.id === innerZone.id,
            )
            if (matchingBackendInnerZone) {
              updateZoneStatus(innerZone, matchingBackendInnerZone)
            }
          }
        }
      }

      for (const floor of Object.keys(state)) {
        for (const zone of state[floor]) {
          const matchingBackendZone = zonesFromBackend.find(
            backZone => backZone.id === zone.id,
          )
          if (matchingBackendZone) {
            updateZoneStatus(zone, matchingBackendZone)
          }
        }
      }
    },
  },
})

export const { setZonesStatus } = floors.actions

export const getZonesStatusThunk = () => dispatch => {
  try {
    axios
      .get('/status/park')
      .then(res => dispatch(setZonesStatus(res.data.zones)))
  } catch (err) {
    console.error(err)
  }
}

export default floors.reducer
