import React, { useEffect, useState } from 'react'
import axios from 'axios'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import NativeSelect from '@mui/material/NativeSelect'

export default function MuiSelect({ id, button }) {
  const [theme, setTheme] = useState(
    JSON.parse(localStorage.getItem(`${id}-theme`) || 0),
  )

  const themeHandler = async event => {
    setTheme(event.target.value)
    window.localStorage.setItem(
      `${id}-theme`,
      JSON.stringify(event.target.value),
    )
    try {
      await axios.post(button.url, {
        zone: id,
        command: event.target.value,
        id: button.id,
      })
      console.log(
        'zone theme ->',
        id,
        'newbrightValue-->',
        event.target.value,
        'id button ->',
        button.id,
      )
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <FormControl sx={{ m: 1, minWidth: 150 }}>
      <Select
        labelId="select-label"
        id="select"
        value={theme}
        label="Тема"
        onChange={themeHandler}
      >
        {button.themes.map(theme => (
          <MenuItem value={theme.id}>{theme.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
