import React, { useEffect, useState } from 'react'

import iconMapping from './utility/iconMapping'
import PowerButtons from './PowerButtons'
import ThemesDropdown from './ThemesDropdown'
import BrightSlider from './BrightSlider'
import VolumeSlider from './VolumeSlider'
import ContentButtons from './ContentButtons'
import RestartButton from './RestartButton'

export default function ZoneButtonsContainer({ id, button }) {
  const IconComponent = iconMapping[button.id]

  const renderButtons = (id, button) => {
    if (
      button.url.startsWith('/power') ||
      button.url.startsWith('/light/lamp')
    ) {
      return <PowerButtons id={id} button={button} />
    } else if (button.url.startsWith('/restart')) {
      return <RestartButton id={id} button={button} />
    } else if (button.url.startsWith('/light/mode')) {
      return <ThemesDropdown id={id} button={button} />
    } else if (button.url.startsWith('/light/brightness')) {
      return <BrightSlider id={id} button={button} />
    } else if (button.url.startsWith('/sound/zone')) {
      return <VolumeSlider id={id} button={button} />
    } else if (button.url.startsWith('/content')) {
      return <ContentButtons id={id} button={button} />
    }
  }

  return (
    <div className="zone-button">
      <div className="zone-panel-container">
        <div className="zone-panel-name">
          <div className="panel-logo icon-button">
            {IconComponent ? <IconComponent /> : null}
          </div>
          {button.name}
        </div>
        {renderButtons(id, button)}
      </div>
    </div>
  )
}
