function determineOverallStatus(zone) {
  let statuses = []
  let zonesToProcess = [zone] // Инициализируем массив зон для обработки

  while (zonesToProcess.length > 0) {
    let currentZone = zonesToProcess.pop()

    // Добавляем статусы из текущей зоны
    if (currentZone.status) {
      statuses.push(...Object.values(currentZone.status))
    }

    // Добавляем внутренние зоны для дальнейшей обработки
    if (currentZone.innerZones && Array.isArray(currentZone.innerZones)) {
      zonesToProcess.push(...currentZone.innerZones)
    }
  }

  // Определим приоритетный статус, где 520 err > 520 off > 200 on
  if (statuses.includes(520)) {
    return 'err'
  } else if (statuses.includes(521)) {
    return 'off'
  } else if (statuses.includes(200)) {
    return 'on'
  }

  return null
}

export default determineOverallStatus
