// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body.alert {
  gap: 0px;
}
.modal-body.alert .modal-header {
  justify-content: flex-end;
}

.alert-body {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.logo-cacnel, .logo-restart {
  height: 18px;
}

.alert-title {
  font-weight: 400;
  font-size: 25px;
  line-height: 150%;
  text-align: center;
  color: var(--text);
}

.alert-buttons {
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: center;
}

.alert-button {
  height: 50px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 0px 20px;
  border: none;
  cursor: pointer;
}

.alert-cancel {
  width: 122px;
  background: #f3f3f3;
  color: var(--text);
}
.alert-cancel:hover {
  background: #d8d8d8;
  border-color: #d8d8d8;
}
.alert-cancel:active {
  background: #d8d8d8;
  border-color: #d8d8d8;
}
.alert-cancel .logo-cancel {
  height: 13px;
}

.alert-restart {
  width: 180px;
  background: var(--brand);
  color: var(--white);
}
.alert-restart:hover {
  background: var(--lightBrand);
  border-color: var(--lightBrand);
}
.alert-restart:active {
  background: var(--lightBrand);
  border-color: var(--lightBrand);
}`, "",{"version":3,"sources":["webpack://./src/styles/alertPopUp.scss"],"names":[],"mappings":"AAAA;EACE,QAAA;AACF;AAAE;EACE,yBAAA;AAEJ;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,WAAA;EACA,SAAA;EACA,uBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,SAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,iBAAA;EACA,YAAA;EACA,eAAA;AACF;;AAEA;EACE,YAAA;EACA,mBAAA;EACA,kBAAA;AACF;AAAE;EACE,mBAAA;EACA,qBAAA;AAEJ;AAAE;EACE,mBAAA;EACA,qBAAA;AAEJ;AAAE;EACE,YAAA;AAEJ;;AAEA;EACE,YAAA;EACA,wBAAA;EACA,mBAAA;AACF;AAAE;EACE,6BAAA;EACA,+BAAA;AAEJ;AAAE;EACE,6BAAA;EACA,+BAAA;AAEJ","sourcesContent":[".modal-body.alert {\n  gap: 0px;\n  .modal-header {\n    justify-content: flex-end;\n  }\n}\n\n.alert-body {\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n  align-items: center;\n}\n\n.logo-cacnel, .logo-restart {\n  height: 18px;\n}\n\n.alert-title {\n  font-weight: 400;\n  font-size: 25px;\n  line-height: 150%;\n  text-align: center;\n  color: var(--text);\n}\n\n.alert-buttons {\n  display: flex;\n  width: 100%;\n  gap: 10px;\n  justify-content: center;\n}\n\n.alert-button {\n  height: 50px;\n  display: flex;\n  gap: 10px;\n  justify-content: center;\n  align-items: center;\n  border-radius: 20px;\n  padding: 0px 20px;\n  border: none;\n  cursor: pointer;\n}\n\n.alert-cancel {\n  width: 122px;\n  background: #f3f3f3;\n  color: var(--text);\n  &:hover {\n    background: #d8d8d8;\n    border-color: #d8d8d8;\n  }\n  &:active {\n    background: #d8d8d8;\n    border-color: #d8d8d8;\n  }\n  .logo-cancel {\n    height: 13px;\n  }\n}\n\n.alert-restart {\n  width: 180px;\n  background: var(--brand);\n  color: var(--white);\n  &:hover {\n    background: var(--lightBrand);\n    border-color: var(--lightBrand);\n  }\n  &:active {\n    background:  var(--lightBrand);\n    border-color: var(--lightBrand);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
