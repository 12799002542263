import ZoneStatusValuesComponent from './ZoneStatusValuesComponent'
import React from 'react'

const SingleZoneStatus = ({
  name,
  status,
  isChild,
  withText = false,
  classNameStatus,
  logoStatus,
}) => (
  <div className={`singleZone ${isChild ? 'child' : 'main'}`}>
    <div className={`singleZone-title ${isChild ? 'child' : 'main'}`}>
      {name}
    </div>
    {!withText && (
      <div className="singleZone-status-container">
        <ZoneStatusValuesComponent statusObject={status} />
      </div>
    )}
    {withText && (
      <div className="singleZone-status-container">
        <div
          className={`popup-statuses zone-status zone-status-${classNameStatus}`}
        >
          {classNameStatus === 'err' && 'Ошибка'}
          {classNameStatus === 'on' && 'Включено'}
          {classNameStatus === 'off' && 'Выключено'}
          <div className="zone-status-logo">{logoStatus}</div>
        </div>
      </div>
    )}
  </div>
)

export default SingleZoneStatus
