export const statusToClassName = status => {
  switch (status) {
    case 200:
      return 'on'
    case 520:
      return 'err'
    case 521:
      return 'off'
    default:
      return ''
  }
}
