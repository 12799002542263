import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Slider from '@mui/material/Slider'

export default function VolumeSlider({ id, button }) {
  const [soundValue, setSoundValue] = useState(
    JSON.parse(localStorage.getItem(`${id}-sound`) || 0),
  )
  const soundHandler = async (event, newSoundValue) => {
    setSoundValue(newSoundValue)
    window.localStorage.setItem(`${id}-sound`, JSON.stringify(newSoundValue))
    try {
      await axios.post(button.url, {
        zone: id,
        command: newSoundValue.toString(),
        id: button.id,
      })
      console.log(
        'zone sound ->',
        id,
        'newSoundValue-->',
        newSoundValue.toString(),
        'id button ->',
        button.id,
      )
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <div className="slider-place">
      <Slider aria-label="Volume" value={soundValue} onChange={soundHandler} />
    </div>
  )
}
